@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nerko+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mansalva&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=East+Sea+Dokdo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dokdo&display=swap");

// ------------- MIXINS ------------- //
@mixin transition($time, $property: all, $easing: ease-in) {
  transition: $property $time $easing;
}

// ------------ LAYOUTS ------------ //
$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$xl-layout: 'only screen and (min-width: 1200px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';

// ------------- VARIABLES ------------- //
$parallax-offset: 30vh;
$content-offset: 40vh;
$transition-speed: 1.2s;
$slide-number: 4;
$primary-color: #265766;
$secondary-color: #EB7F9E;
$third-color: #EBDF50;
$fourth-color: #67CCEB;
$button-color: #2b598a;

:root {
  --app-height: 100%;
}

html, body {
  overflow: hidden;
  padding: 0;
  margin: 0;
  font-family: "East Sea Dokdo", cursive, serif;
  font-size: 1.2rem;
  background-color: #fff;
  color: #fff;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  -webkit-touch-callout: none;                /* prevent callout to copy image, etc when tap to hold */
  -webkit-user-select: none;                  /* prevent copy paste, to allow, change 'none' to 'text' */
}

.container {
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
}

.lock-landscape {
  display:none;
  width: 100vw;
  height: 100vh;
  color: #000;
  font-size: 3rem;
  font-weight: 900;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

@media screen and (max-width: 480px) and (max-height: 767px) and (orientation:landscape) {
  .lock-landscape { display:flex; }
  .container { display:none; }
}

.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  will-change: transform;
  backface-visibility: hidden;
  height: 100vh + $parallax-offset;
  position: fixed;
  width: 100%;
  transform: translateY($parallax-offset);
  @include transition($transition-speed, all, cubic-bezier(0.22, 0.44, 0, 1));
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.3);
  }
  &:first-child {
    transform: translateY(-$parallax-offset / 2);
    .content-wrapper {
      transform: translateY($parallax-offset / 2);
    }
  }
}

/* Set stacking context of slides */
@for $i from 1 to ($slide-number + 1) {
  .background:nth-child(#{$i}) {
    z-index: ($slide-number + 1) - $i;
  }
}

// ------------- SET TRANSFORM VALUES ------------- //

.background.up-scroll {
  transform: translate3d(0, -$parallax-offset / 2, 0);
  .content-wrapper {
    transform: translateY($parallax-offset / 2);
  }
  + .background {
    transform: translate3d(0, $parallax-offset, 0);
    .content-wrapper {
      transform: translateY($parallax-offset);
    }
  }
}

.background.down-scroll {
  transform: translate3d(0, -(100vh + $parallax-offset), 0);
  .content-wrapper {
    transform: translateY($content-offset);
  }
  + .background:not(.down-scroll) {
    transform: translate3d(0, -$parallax-offset / 2, 0);
    .content-wrapper {
      transform: translateY($parallax-offset / 2);
    }
  }
}

.content {
  line-height: 1.4;

  &-wrapper {
    height: calc(100vh - 2em);
    padding: 1em;
    //margin-top: -1em;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-flow: column nowrap;
    color: #fff;
    text-transform: uppercase;
    transform: translateY($content-offset);
    will-change: transform;
    backface-visibility: hidden;
    @include transition($transition-speed + .5, all, cubic-bezier(0.22, 0.44, 0, 1));
  }

  &-title {
    font-weight: 900;
    @media #{$xl-layout} {
      font-size: 5em;
    }
    @media #{$lg-layout} {
      font-size: 4em;
    }
    @media #{$md-layout} {
      font-size: 3em;
    }
    @media #{$sm-layout} {
      font-size: 2em;
    }

    &-header {
      position: relative;
      display: flex;
      align-self: center;
      line-height: 8px;
      font-weight: 900;
      @media #{$xl-layout} {
        line-height: 10px;
        margin-left: 7.5em;
        font-size: 2em;
      }
      @media #{$lg-layout} {
        line-height: 10px;
        margin-left: 5.5em;
        font-size: 1.8em;
      }
      @media #{$md-layout} {
        margin-left: 4.5em;
        font-size: 1.5em;
      }
      @media #{$sm-layout} {
        margin-left: 3em;
        font-size: 1.2em;
      }
      &:after {
        content: "";
        height: 8px;
        display: block;
        background-color: $secondary-color;
        position: absolute;
        @media #{$xl-layout} {
          height: 10px;
          width: 7em;
          left: -7.5em;
        }
        @media #{$lg-layout} {
          height: 10px;
          width: 5em;
          left: -5.5em;
        }
        @media #{$md-layout} {
          width: 4em;
          left: -4.5em;
        }
        @media #{$sm-layout} {
          width: 2.5em;
          left: -3em;
        }
      }
    }
  }

  &-subtitle {
    display: flex;
    align-self: center;
    padding: 0 .5em 2em;
    font-weight: 900;
  }

  &-subtitle-intro {
    display: flex;
    align-items: center;
    position: fixed;
    padding: 1em .5em;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    font-weight: 900;
    @media #{$sm-layout} {
      transform: translate(-50%, -25%);
      flex-direction: column-reverse;
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse-fas {
  0% {
    opacity: 0.7;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.blob {
  background: transparent;
  color: white;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  margin: 0 20px;
  height: 30px;
  width: 30px;
  transform: scale(1);
  animation: pulse 2s infinite;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media #{$sm-layout} {
    margin: 20px 0;
  }
  .fas {
    font-size: 1rem;
    animation: pulse-fas 2s infinite;
  }
}

@mixin glitchCopy {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch {
  position: relative;
  color: #FFF;
  font-weight: 900;
  animation: glitch-skew 1s infinite linear alternate-reverse;

  &::before {
    @include glitchCopy;
    left: 2px;
    text-shadow: -2px 0 var(--color-primary);
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim 5s infinite linear alternate-reverse;
  }

  &::after {
    @include glitchCopy;
    left: -2px;
    text-shadow: -2px 0 $fourth-color, 2px 2px $third-color;
    animation: glitch-anim2 1s infinite linear alternate-reverse;
  }

  @media #{$xl-layout} {
    font-size: 9em;
  }
  @media #{$lg-layout} {
    font-size: 7em;
  }
  @media #{$md-layout} {
    font-size: 5em;
  }
  @media #{$sm-layout} {
    font-size: 3em;
  }
}

@keyframes glitch-anim {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      clip: rect(random(100)+px, 9999px, random(100)+px, 0);
      transform: skew((random(100) / 100) + deg);
    }
  }
}

@keyframes glitch-anim2 {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      clip: rect(random(100)+px, 9999px, random(100)+px, 0);
      transform: skew((random(100) / 100) + deg);
    }
  }
}

@keyframes glitch-skew {
  $steps: 10;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      transform: skew((random(10) - 5) + deg);
    }
  }
}

.MuiFormControl-root {
  @media #{$xl-layout} {
    width: 25vw;
  }
  @media #{$lg-layout} {
    width: 30vw;
  }
  @media #{$md-layout} {
    width: 40vw;
  }
  @media #{$sm-layout} {
    width: 60vw;
  }
}

.MuiInputLabel-root, .MuiInputAdornment-root, .MuiInput-root, .MuiButton-root {
  color: white !important;
  font-family: "East Sea Dokdo", cursive, serif !important;
  font-size: 1.2rem !important;
}

.MuiInputLabel-root {
  font-weight: 700 !important;
}

.MuiInput-root::before {
  border-bottom: 1px solid rgba(255, 255, 255, 0.42) !important;
}

.MuiInput-root::after {
  border-bottom: 2px solid #fff !important;
}

.MuiButton-root {
  color: white !important;
  background: $button-color !important;
}

.MuiButton-root:hover {
  background: darken($button-color, 15%) !important;
}

.card-container {
  width: 100%;
  display: flex;
  align-items: center;
  height: 60vh;
  justify-content: center;
  cursor: pointer;
  @media #{$sm-layout} {
    align-items: flex-start;
    //height: 55vh;
  }
}

.card {
  position: absolute;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.card > div {
  position: relative;
  width: calc(min(40vh, 25vw));
  background-color: white;
  box-shadow: 0 12.5px 20px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
  will-change: transform;
  border-radius: 10px;
  border: 10px solid white;
  touch-action: none;
  overflow: hidden;
  @media #{$sm-layout} {
    width: calc(min(25vh, 55vw));
  }
}

.card-contents {
  width: 100%;
  color: black;

  .image-container {
    position: relative;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .image-container:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  //img {
  //  user-drag: none;
  //  -webkit-user-drag: none;
  //  user-select: none;
  //  -moz-user-select: none;
  //  -webkit-user-select: none;
  //  -ms-user-select: none;
  //}

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3 {
    margin-block-start: .5em;
    margin-block-end: .5em;
  }

  h5 {
    color: darkgray;
    text-transform: none;
    font-weight: normal;
    margin-block-start: .5em;
    margin-block-end: 1.5em;
  }
}

.contactus {
  gap: .5em;
  display: flex;
  align-items: center;
  height: 60vh;
  justify-content: center;

  @media #{$sm-layout} {
    align-items: flex-start;
  }
}
